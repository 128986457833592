<template lang="html" src="./pageQuotes.template.vue"></template>

<style lang="scss" src="./pageQuotes.scss"></style>

<script>
import { ApiErrorParser, DataTableFilter, CustomFilterForSearchDatatable } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageQuotes.i18n');

export default {
  name: 'PageQuotes',
  components: { DataTableFilter },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      filterObject: {},
      headers: [],
      quoteList: [],
      loading: false,
      loadingTable: false,
      search: '',
      statusFilter: [],
      typeFilter: []
    };
  },
  created() {
    this.getBrandQuotes();
  },
  mounted() {
    this.filterObject = {
      status: [
        {
          name: this.$t('QuoteLocked'),
          value: 'LOCKED', 
          icon: 'fas fa-lock',
          get selected() {
            return this.number > 0;
          },
          number: 0,
        },
        {
          name: this.$t('QuoteReady'),
          value: 'READY', 
          iconColor: 'warning',
          icon: 'fas fa-clock',
          get selected() {
            return this.number > 0;
          },
          number: 0,
        },
        {
          name: this.$t('QuoteDeclined'),
          value: 'DECLINED', 
          iconColor: 'error',
          icon: 'fas fa-times-circle',
          number: 0,
        },
        {
          name: this.$t('QuoteAccepted'),
          value: 'ACCEPTED', 
          iconColor: 'success',
          icon: 'fas fa-check-circle',
          number: 0,
        }
      ]
    };
    this.headers = [
      {
        text: this.$t('Status'),
        value: 'status',
        align: 'center',
        width: '100px',
        filter: (value) => {
          if (this.statusFilter.length === 0) return true;
          if (this.statusFilter.includes(value) || (value === 'CLOSED' && this.statusFilter.includes('ACCEPTED'))) return true;
        },
      },
      {
        text: this.$t('QuoteID'),
        value: 'quoteNumber',
      },
      {
        text: this.$t('PurchaseOrderNumber'),
        value: 'purchaseOrderNumber',
      },
      {
        text: this.$t('TotalAmount'),
        value: 'selling.totalAmount',
        align: 'right',
      },
      { text: this.$t('Created'), value: 'created', align: 'center' },
      {
        text: this.$t('Action'),
        value: 'action',
        align: 'center',
        sortable: false,
      },
    ];
    if(this.$appConfig.brand.features.privateSuppliers.length) {
      this.filterObject['type'] = [
        {
          name: 'CMT',
          value: 'public', 
          img: 'logo_cut.png',
          number: 0,
        },
        {
          name: this.$t('PrivateSupplier'),
          value: 'private', 
          iconColor: 'privateSupplier',
          icon: 'fas fa-warehouse',
          number: 0,
        }
      ];

      this.headers.splice(1, 0, {
        text: this.$t('Type'),
        value: 'public',
        width: '100px',
        align: 'center',
        sortable: false,
        filter: (value) => {
          if (this.typeFilter.length === 0) return true;
          if ((value && this.typeFilter.includes('public')) || (!value && this.typeFilter.includes('private')) ) return true;
        },
      });
    }
  },
  methods: {
    customFilter(value, search, item) {
      return CustomFilterForSearchDatatable.customFilter(value, search, this);
    },
    getBrandQuotes() {
      this.loadingTable = true;
      /**
       * GET BRAND QUOTES
       */
      this.$apiInstance
        .getBrandQuotes(this.$appConfig.currentBrand)
        .then((data) => {
          this.quoteList = data;
          /**
           * Reset Filter Object
           */
          for (const key in this.filterObject) {
            for (const obj of this.filterObject[key]) {
              obj.number = 0;
            }
          }
          this.quoteList.forEach(quote => {
            this.filterObject.status.find(x => x.value === quote.status || (x.value === 'ACCEPTED' && quote.status === 'CLOSED')).number++;
            if(this.$appConfig.brand.features.privateSuppliers.length) {
              this.filterObject.type.find(x => (x.value === 'public' && quote.public) || (x.value === 'private' && !quote.public)).number++;
            }
          });
        })
        .catch((error) => {
          /**
           * ERROR GET BRAND QUOTES
           */
          ApiErrorParser.parse(error);
        })
        .finally(() => {
          this.loadingTable = false;
          this.loading = false;
        });
    },
    updateFilter(update) {
      this.statusFilter = update.status;
      if(update.type) {
        this.typeFilter = update.type;
      }
    },
    refreshPage() {
      this.loading = true;
      this.getBrandQuotes();
    },
    goToPageItem(quote) {
      this.$router.push({name: 'Quote', params: {brandUUID: `${this.$appConfig.currentBrand}`, quoteUUID: `${quote._id}`}});
    },
  },
};
</script>
